import { TFunction, withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ContentBlock from '../common/ContentBlock';
import { scrollToSection } from '../common/utils/utils';
import { RouteName } from '../router/config';

function IntroSection(props: { t: TFunction }) {
  const { t } = props;
  const navigate = useNavigate();

  const handleNewSiteClick = () => {
    navigate(RouteName.siteForm);
  };
  const handleEditClick = () => {
    scrollToSection('login');
  };
  return (
    <ContentBlock
      title={t('introSection.title')}
      content={t('introSection.body')}
      button={[
        { title: t('introSection.editButton'), onClick: handleEditClick },
        { title: t('introSection.newButton'), onClick: handleNewSiteClick },
      ]}
      id="main"
      direction="right"
      icon="intro.svg"
    />
  );
}
export default withTranslation()(IntroSection);
