import 'antd/dist/reset.css';

import { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Contact from '../pages/Contact';
import Home from '../pages/Home';
import SiteForm from '../pages/SiteForm';
import SitePage from '../pages/SitePage';
import FooterSection from '../sections/FooterSection';
import HeaderSection from '../sections/HeaderSection';
import { Styles } from '../styles/styles';
import { RouteName } from './config';
import Services from '../pages/Services';
import logger from '../services/logger';

const Router = () => {
  useEffect(() => {
    logger.info('Site opened!', { href: window.location.href });
  }, []);
  return (
    <Suspense fallback={<div>loading...</div>}>
      <Styles />
      <HeaderSection />
      <Routes>
        <Route path="/" element={<Navigate to={RouteName.home} replace />} />
        <Route path={RouteName.home} element={<Home />} />
        <Route path={RouteName.contact} element={<Contact />} />
        <Route path={RouteName.siteForm} element={<SiteForm />} />
        <Route path={RouteName.sitePage} element={<SitePage />} />
        <Route path={RouteName.services} element={<Services />} />
        <Route path="*" element={<h6>Not Found</h6>} />
      </Routes>

      <FooterSection />
    </Suspense>
  );
};

export default Router;
