import { TFunction, withTranslation } from 'react-i18next';

import ContentBlock from '../common/ContentBlock';

function PricingSection(props: { t: TFunction }) {
  const { t } = props;
  return (
    <ContentBlock
      title={t('pricingSection.title')}
      content={t('pricingSection.body')}
      id="price"
      direction="right"
    />
  );
}

export default withTranslation()(PricingSection);
