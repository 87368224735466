import axios from 'axios';
import { useMutation, useQuery } from 'react-query';

import queryClient from '../common/queryClient';
import { serverApi } from './server';

export interface SiteContent {
  name?: string;
  address_text?: string;
  hours?: string;

  phone?: string;
  zalo?: string;
  instagram?: string;
  facebook?: string;

  logo_url?: string;
  main_image_url?: string;
  image_urls?: string[];
  menu_urls?: string[];

  about?: string;
  google_maps?: string;
  tripadvisor?: string;

  owner_email?: string;
  subdomain?: string;
}

export enum RunStatus {
  IN_PROGRESS = 'in_progress',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export function useGetSite(siteId?: string) {
  return useQuery(
    ['sites', siteId],
    async () => {
      const { data } = await serverApi.get<SiteContent>(
        `/api/v1/sites/${siteId}`,
      );
      return data;
    },
    { enabled: !!siteId },
  );
}

export function useCreateSite() {
  return useMutation(async () => {
    const { data } = await serverApi.post<string>('/api/v1/sites');
    return data;
  });
}

export function useUpdateSite(siteId: string) {
  return useMutation({
    mutationFn: async (params: { siteContent: SiteContent }) => {
      const { data } = await serverApi.put<SiteContent>(
        `/api/v1/sites/${siteId}`,
        params.siteContent,
      );
      return data;
    },
    onSuccess: (data: SiteContent) => {
      queryClient.setQueryData(['sites', siteId], data);
    },
  });
}

export function usePublishSite(siteId: string) {
  return useMutation(
    async () => {
      const { data } = await serverApi.post<string>(
        `/api/v1/sites/${siteId}/publish`,
        undefined,
        { timeout: 40000 },
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sites', siteId]);
      },
    },
  );
}

export function useCheckPublishSiteStatus(
  siteId: string,
  runId: string | null,
) {
  return useQuery(
    ['run', runId],
    async () => {
      const { data } = await serverApi.get<RunStatus>(
        `/api/v1/sites/${siteId}/publish/${runId}`,
      );
      return data;
    },
    { enabled: !!runId, refetchInterval: runId ? 3000 : false },
  );
}

export function useUpdateSiteOwner(siteId: string) {
  return useMutation(
    async (email: string) => {
      const { data } = await serverApi.patch<SiteContent>(
        `/api/v1/sites/${siteId}/owner`,
        { email },
      );
      return data;
    },
    {
      onSuccess: (data: SiteContent) => {
        queryClient.setQueryData(['site', siteId], data);
      },
    },
  );
}

const retry = (failureCount: number, error: Error) => {
  if (axios.isAxiosError(error)) {
    if (error.status === 404) {
      return false;
    }
  }
  if (failureCount >= 2) {
    return false;
  }
  return true;
};

export function useGetSiteByOwner() {
  return useMutation(
    async (ownerEmail: string) => {
      const { data } = await serverApi.get<string>('/api/v1/sites', {
        params: { owner_email: ownerEmail },
      });
      return data;
    },
    { retry: retry },
  );
}
