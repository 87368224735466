import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ContentBlock from '../common/ContentBlock';
import EmailInput from '../components/EmailInput';
import {
  LocalstorageKey,
  useGetOrCreateSite,
} from '../components/useGetOrCreateSite';
import { RouteName } from '../router/config';
import { useGetSiteByOwner } from '../services/requests';

function LoginSection(props: { t: TFunction }) {
  const { t } = props;

  const getCurrentSite = useGetOrCreateSite({ allowCreate: false });
  const getSiteByOwnerMutation = useGetSiteByOwner();
  const navigate = useNavigate();
  const [isNotFound, setIsNotFound] = useState<boolean>(false);

  const handleEmailChange = (email: string) => {
    if (isNotFound) {
      setIsNotFound(false);
    }
  };
  const handleSuccess = () => {
    navigate(RouteName.sitePage);
  };

  const handleEmailSubmit = async (email: string) => {
    try {
      const res = await getSiteByOwnerMutation.mutateAsync(email);
      localStorage.setItem(LocalstorageKey.siteId, res);
      handleSuccess();
    } catch (e) {
      setIsNotFound(true);
    }
  };

  return (
    <ContentBlock
      title={t('loginSection.title')}
      content={
        getCurrentSite.siteContent?.name
          ? t('loginSection.loggedInBody')
          : t('loginSection.body')
      }
      id="login"
      direction="right"
    >
      {!getCurrentSite.siteContent?.name && (
        <EmailInput
          onSubmit={handleEmailSubmit}
          isLoading={getSiteByOwnerMutation.isLoading}
          onChange={handleEmailChange}
          errorText={isNotFound ? t('loginSection.emailNotFound') : undefined}
        />
      )}
      {getCurrentSite.siteContent?.name && (
        <div style={{ padding: 40 }}>
          <Button
            icon={<EditOutlined />}
            onClick={handleSuccess}
            size="large"
            shape="round"
            type="primary"
          >
            {getCurrentSite.siteContent.name}
          </Button>
        </div>
      )}
    </ContentBlock>
  );
}

export default withTranslation()(LoginSection);
