import axios from 'axios';

import { SERVER_URL } from './common';

const TIMEOUT = 5000;
const loggerApi = axios.create({
  baseURL: SERVER_URL,
  timeout: TIMEOUT,
  headers: {
    'ngrok-skip-browser-warning': true,
    'Content-Type': 'application/json',
  },
});

enum LogLevel {
  info = 'info',
  warning = 'warning',
  error = 'error',
}

interface ClientLog {
  level: LogLevel;
  message: string;
  extra: object;
}

function sendLog(level: LogLevel, message: string, extra?: object) {
  const log: ClientLog = { level, message, extra: extra || {} };
  loggerApi.post('/api/v1/logs', log).catch((e) => {
    console.error('Failed to send log');
    console.error(e);
    console.error(e.stack);
  });
}

class Logger {
  info(message: string, extra?: object) {
    console.info(message, extra);
    sendLog(LogLevel.info, message, extra);
  }
  warning(message: string, extra?: object) {
    console.warn(message, extra);
    sendLog(LogLevel.warning, message, extra);
  }
  error(message: string, extra?: object, error?: unknown) {
    console.error(message, extra);
    console.error(error);
    let extraData = extra || {};
    if (error instanceof Error)
      extraData = {
        ...extraData,
        'error.message': error?.message,
        'error.stack': error?.stack,
        'error.name': error?.name,
        'error.cause': error?.cause,
      };
    sendLog(LogLevel.error, message, extraData);
  }
}

const logger = new Logger();
export default logger;
