import { Button, Col, Flex, Row, Steps } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { SERVER_URL } from '../../services/common';
import { SiteContent, useUpdateSite } from '../../services/requests';
import ErrorPage from '../ErrorPage';
import LoaderPage from '../LoaderPage';
import { useGetOrCreateSite } from '../useGetOrCreateSite';
import AboutForm from './AboutForm';
import BasicForm from './BasicForm';
import ContactForm from './ContactForm';
import ImagesForm from './ImagesForm';
import { Publish } from './Publish';
import Review from './Review';
import { SaveForLater } from './SaveForLater';
import { scrollToSection } from '../../common/utils/utils';
import MiddleBlock from '../../common/MiddleBlock';

export function SiteCreationWrapper() {
  const getCurrentSite = useGetOrCreateSite({ allowCreate: true });

  if (getCurrentSite.isError) {
    return <ErrorPage />;
  }

  if (!getCurrentSite.siteId || !getCurrentSite.siteContent) {
    return <LoaderPage />;
  }

  return (
    <SiteCreation
      siteId={getCurrentSite.siteId}
      siteContent={getCurrentSite.siteContent}
    />
  );
}

export function SiteCreation(props: {
  siteId: string;
  siteContent: SiteContent;
}) {
  const updateSiteMutation = useUpdateSite(props.siteId);

  const updateSite = (siteContent: SiteContent) => {
    updateSiteMutation.mutateAsync({ siteContent });
  };

  return (
    <SiteCreationForm
      currentSiteContent={props.siteContent}
      updateSite={updateSite}
      uploadUrl={`${SERVER_URL}/api/v1/sites/${props.siteId}/images/upload`}
      siteId={props.siteId}
    />
  );
}

function FormButtons(props: {
  prev: boolean;
  next: boolean;
  skip: boolean;
  onPrev: () => void;
  onNext: () => void;
  onSkip: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Flex justify="space-between" style={{ padding: 30 }}>
      {props.prev ? (
        <Button onClick={props.onPrev} disabled={!props.prev}>
          {t('form.prev')}
        </Button>
      ) : (
        <div />
      )}
      <Flex gap="small">
        {props.skip ? (
          <Button disabled={!props.skip} onClick={props.onSkip}>
            {t('form.skip')}
          </Button>
        ) : (
          <div />
        )}
        {props.next ? (
          <Button type="primary" disabled={!props.next} onClick={props.onNext}>
            {t('form.next')}
          </Button>
        ) : (
          <div />
        )}
      </Flex>
    </Flex>
  );
}

export enum StepName {
  INTRO = 'intro',
  BASIC = 'basic',
  CONTACT = 'contact',
  ABOUT = 'about',
  IMAGES = 'images',
  REVIEW = 'review',
  SAVE_FOR_LATER = 'save',
  PUBLISH = 'publish',
}
const STEPS_ORDER = [
  StepName.INTRO,
  StepName.BASIC,
  StepName.CONTACT,
  StepName.ABOUT,
  StepName.IMAGES,
  StepName.REVIEW,
  StepName.SAVE_FOR_LATER,
  StepName.PUBLISH,
];

export function SiteCreationForm(props: {
  siteId: string;
  currentSiteContent: SiteContent;
  updateSite: (siteContent: SiteContent) => void;
  uploadUrl: string;
}) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStep = searchParams.get('step')
    ? (searchParams.get('step') as StepName)
    : StepName.INTRO;

  const setCurrentStep = (step: StepName) => setSearchParams({ step });

  const currentStepIndex = useMemo(() => {
    return STEPS_ORDER.findIndex((s) => s === currentStep);
  }, [currentStep]);

  const [draft, setDraft] = useState<SiteContent>(props.currentSiteContent);

  const getValue = (valName: keyof SiteContent) => draft[valName];
  const setValue = (valName: keyof SiteContent, newValue: any) => {
    setDraft((p) => ({ ...p, [valName]: newValue }));
  };
  const handleNext = () => {
    if (currentStep === StepName.BASIC) {
      if (!draft.name || !draft.address_text || !draft.hours) {
        return;
      }
    }
    props.updateSite(draft);
    setCurrentStep(STEPS_ORDER[currentStepIndex + 1]);
    scrollToSection('top');
  };

  const handleSkip = () => {
    setCurrentStep(STEPS_ORDER[currentStepIndex + 1]);
  };

  const handlePrev = () => {
    setCurrentStep(STEPS_ORDER[currentStepIndex - 1]);
  };

  const handleSave = () => {
    setCurrentStep(StepName.SAVE_FOR_LATER);
  };
  const handlePublish = () => {
    setCurrentStep(StepName.PUBLISH);
  };

  const steps = [
    {
      component: (
        <MiddleBlock
          id="intro"
          title={t('formIntro.title')}
          content={t('formIntro.body')}
        >
          <Button
            onClick={handleNext}
            color="primary"
            type="primary"
            size="large"
          >
            {t('formIntro.button')}
          </Button>
        </MiddleBlock>
      ),
      skip: false,
      next: false,
      prev: false,
      stepNum: undefined,
    },
    {
      component: <BasicForm getValue={getValue} setValue={setValue} />,
      skip: false,
      next: true,
      prev: false,
      stepNum: 0,
    },
    {
      component: <ContactForm getValue={getValue} setValue={setValue} />,
      skip: true,
      next: true,
      prev: true,
      stepNum: 1,
    },
    {
      component: <AboutForm getValue={getValue} setValue={setValue} />,
      skip: true,
      next: true,
      prev: true,
      stepNum: 2,
    },
    {
      component: (
        <ImagesForm
          getValue={getValue}
          setValue={setValue}
          uploadUrl={props.uploadUrl}
        />
      ),
      skip: true,
      next: true,
      prev: true,
      stepNum: 3,
    },
    {
      component: (
        <Review
          onEdit={handlePrev}
          siteId={props.siteId}
          onPublish={handlePublish}
          onSave={handleSave}
        />
      ),
      skip: false,
      next: false,
      prev: false,
      stepNum: 4,
    },
    {
      component: (
        <SaveForLater
          siteId={props.siteId}
          ownerEmail={props.currentSiteContent.owner_email}
        />
      ),
      skip: false,
      next: false,
      prev: true,
      stepNum: 4,
    },
    {
      component: (
        <Publish
          siteId={props.siteId}
          siteContent={props.currentSiteContent}
          ownerEmail={props.currentSiteContent.owner_email}
        />
      ),
      skip: false,
      next: false,
      prev: false,
      stepNum: 4,
    },
  ];

  return (
    <Row justify="center">
      <Col lg={14} md={14} sm={20} xs={24}>
        <Flex
          justify="space-between"
          id="steps"
          vertical
          style={{
            minHeight: '75vh',
            padding: '30px 16px',
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            margin: '30px 0',
          }}
        >
          {steps[currentStepIndex].stepNum !== undefined && (
            <Steps
              responsive={false}
              direction="horizontal"
              current={steps[currentStepIndex].stepNum}
              items={Array(5).fill({})}
              style={{ paddingBottom: '20px' }}
            />
          )}
          <>
            <div id={'top'} />
            {steps[currentStepIndex].component}
          </>
          <FormButtons
            skip={steps[currentStepIndex].skip}
            next={steps[currentStepIndex].next}
            prev={steps[currentStepIndex].prev}
            onPrev={handlePrev}
            onNext={handleNext}
            onSkip={handleSkip}
          />
        </Flex>
      </Col>
    </Row>
  );
}
