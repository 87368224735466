import { Col, Row } from 'antd';
import { ReactNode } from 'react';
import { Slide } from 'react-awesome-reveal';
import { TFunction, withTranslation } from 'react-i18next';

import { Button } from '../Button';
import { TextContent } from '../styles';
import { ContentWrapper, MiddleBlockSection } from './styles';

interface MiddleBlockProps {
  title: string;
  content: string;
  button?: string;
  t: TFunction;
  id: string;
  children?: ReactNode;
  onClick?: () => void;
}

const MiddleBlock = ({
  title,
  content,
  button,
  id,
  children,
  onClick,
}: MiddleBlockProps) => {
  return (
    <MiddleBlockSection>
      <Slide direction="up" triggerOnce>
        <Row justify="center" align="middle" id={id}>
          <ContentWrapper>
            <Col lg={24} md={24} sm={24} xs={24}>
              <h6>{title}</h6>
              <TextContent>{content}</TextContent>
              {children && children}
              {button && onClick && (
                <Button onClick={onClick} name="submit">
                  {button}
                </Button>
              )}
            </Col>
          </ContentWrapper>
        </Row>
      </Slide>
    </MiddleBlockSection>
  );
};

export default withTranslation()(MiddleBlock);
