import { lazy } from 'react';

import SiteDashboard from '../components/SiteDashboard';

const Container = lazy(() => import('../common/Container'));

const SitePage = () => {
  return (
    <Container>
      <SiteDashboard />
    </Container>
  );
};

export default SitePage;
