import { Button, Dropdown, Row, Space } from 'antd';

import { SvgIcon } from '../../common/SvgIcon';
import i18n from '../../translation';
import LanguageButton from './LanguageButton';

const LanguageDropdown = () => {
  return (
    <div style={{ display: 'inline-block', padding: '10px' }}>
      <Row justify="center">
        <Dropdown
          overlayStyle={{ minWidth: '0px' }}
          menu={{
            items: [
              {
                key: '1',
                icon: <LanguageButton lng="en" size={20} />,
              },
              {
                key: '2',
                icon: <LanguageButton lng="ko" size={20} />,
              },
              {
                key: '3',
                icon: <LanguageButton lng="vi" size={20} />,
              },
              {
                key: '4',
                icon: <LanguageButton lng="ru" size={20} />,
              },
            ],
          }}
        >
          <Button onClick={(e) => e.preventDefault()} type="text">
            <Space>
              <SvgIcon
                src={`${i18n.language}.svg`}
                aria-label="homepage"
                width="20px"
                height="20px"
              />
            </Space>
          </Button>
        </Dropdown>
      </Row>
    </div>
  );
};

export default LanguageDropdown;
