import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, message, Upload } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const UploadButton = () => {
  const { t } = useTranslation();
  return (
    <Button type="primary" icon={<UploadOutlined />}>
      {t('form.upload')}
    </Button>
  );
};

const beforeUpload = (file: any) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
    return Upload.LIST_IGNORE;
  }
  //   const isLt2M = file.size / 1024 / 1024 < 2;
  //   if (!isLt2M) {
  //     message.error('Image must smaller than 2MB!');
  //   }
  //   return isJpgOrPng && isLt2M;
  return true;
};

function FilesUpload({
  initialUrls,
  count,
  uploadUrl,
  onUpdate,
}: {
  onUpdate: (newValue: string[]) => void;
  initialUrls: string[];
  count: number;
  uploadUrl: string;
}) {
  const [imageUrls, setImageUrls] = useState(() => {
    return initialUrls;
  });
  console.log(imageUrls);

  const handleChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      console.log('info.file.response', info.file.response);

      const newImagesUrls = [...imageUrls, info.file.response];
      setImageUrls(newImagesUrls);
      onUpdate(newImagesUrls);
    }
  };
  const handleRemove: UploadProps['onRemove'] = (info) => {
    const newImageUrls = [...imageUrls];
    const index = newImageUrls.indexOf(info.response);
    if (index > -1) {
      newImageUrls.splice(index, 1);
    }
    setImageUrls(newImageUrls);
    onUpdate(newImageUrls);
  };

  return (
    <Upload
      accept="image/png, image/jpeg"
      name="file"
      listType="picture"
      showUploadList
      action={uploadUrl}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      onRemove={handleRemove}
      defaultFileList={initialUrls.map((url, i) => ({
        uid: '' + i,
        name: url.split('/')[url.split('/').length - 1],
        url,
        response: url,
        status: 'done',
      }))}
      multiple
      maxCount={count}
    >
      {imageUrls.length < count && <UploadButton />}
    </Upload>
  );
}

export default FilesUpload;
