import { Col } from 'antd';
import { Fade } from 'react-awesome-reveal';
import { withTranslation } from 'react-i18next';

import { Button } from '../Button';
import { TextContent } from '../styles';
import { SvgIcon } from '../SvgIcon';
import {
  ButtonWrapper,
  ContentSection,
  ContentWrapper,
  StyledRow,
} from './styles';
import { ContentBlockProps } from './types';

const ContentBlock = ({
  icon,
  title,
  content,
  button,
  id,
  direction,
  iconStyle,
  children,
}: ContentBlockProps) => {
  return (
    <ContentSection>
      <Fade direction={direction} triggerOnce>
        <StyledRow
          justify="space-between"
          align="middle"
          id={id}
          direction={direction}
        >
          <Col lg={11} md={11} sm={12} xs={24}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {icon && (
                <SvgIcon
                  src={icon}
                  width={iconStyle?.width || '60%'}
                  height={iconStyle?.height || '60%'}
                />
              )}
            </div>
          </Col>
          <Col lg={11} md={11} sm={11} xs={24}>
            <ContentWrapper>
              <h6>{title}</h6>
              <TextContent>{content}</TextContent>
              {children}
              <ButtonWrapper>
                {typeof button === 'object' &&
                  button.map(
                    (
                      item: {
                        color?: string;
                        title: string;
                        onClick: () => void;
                      },
                      id: number,
                    ) => {
                      return (
                        <Button
                          key={id}
                          color={item.color}
                          onClick={item.onClick}
                        >
                          {item.title}
                        </Button>
                      );
                    },
                  )}
              </ButtonWrapper>
            </ContentWrapper>
          </Col>
        </StyledRow>
      </Fade>
    </ContentSection>
  );
};

export default withTranslation()(ContentBlock);
