import { Label, StyledContainer, StyledTextArea } from './styles';

export interface InputProps {
  name: string;
  label: string;
  placeholder: string;
  type?: string;
  value?: string;
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
}

const TextArea = ({ label, name, placeholder, onChange }: InputProps) => (
  <StyledContainer>
    <Label htmlFor={name}>{label}</Label>
    <StyledTextArea
      placeholder={placeholder}
      id={name}
      name={name}
      onChange={onChange}
    />
  </StyledContainer>
);

export default TextArea;
