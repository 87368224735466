import { SaveOutlined } from '@ant-design/icons';
import { Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import MiddleBlock from '../../common/MiddleBlock';
import { useUpdateSiteOwner } from '../../services/requests';
import EmailInput from '../EmailInput';

export function SaveForLater(props: { siteId: string; ownerEmail?: string }) {
  const siteOwnerMutation = useUpdateSiteOwner(props.siteId);
  const handleUpdateOwnerEmail = (ownerEmail: string) => {
    siteOwnerMutation.mutateAsync(ownerEmail);
  };
  const { t } = useTranslation();
  return (
    <MiddleBlock
      id="save"
      title=""
      content={props.ownerEmail ? t('save.title') : t('save.signupTitle')}
    >
      <Flex vertical justify="center" gap="large">
        {!props.ownerEmail ? (
          <>
            <Typography.Text>{t('save.signupBody')}</Typography.Text>
            <EmailInput onSubmit={handleUpdateOwnerEmail} />
          </>
        ) : (
          <>
            <div>
              <SaveOutlined />
            </div>
            <Typography.Text>{t('save.body')}</Typography.Text>
          </>
        )}
      </Flex>
    </MiddleBlock>
  );
}
