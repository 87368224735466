import Container from '../common/Container';
import ServicesSection from '../sections/ServicesSection';

export default function Component(): JSX.Element {
  return (
    <Container>
      <ServicesSection />
    </Container>
  );
}
