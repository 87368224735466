import { Col, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { SiteContent } from '../../services/requests';
import FormItem from './FormItem';

export default function AboutForm(props: {
  getValue: (valName: keyof SiteContent) => any;
  setValue: (valName: keyof SiteContent, newValue: any) => void;
}) {
  return (
    <Col>
      <FormItem field="googleMaps">
        <Input
          size="large"
          value={props.getValue('google_maps')}
          onChange={(e) => props.setValue('google_maps', e.target.value)}
        />
      </FormItem>
      <FormItem field="tripadvisor">
        <Input
          size="large"
          value={props.getValue('tripadvisor')}
          onChange={(e) => props.setValue('tripadvisor', e.target.value)}
        />
      </FormItem>
      <FormItem field="about">
        <TextArea
          size="middle"
          rows={8}
          value={props.getValue('about')}
          onChange={(e) => props.setValue('about', e.target.value)}
        />
      </FormItem>
    </Col>
  );
}
