import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const LogoContainer = styled(Link)`
  display: flex;
`;

export const LogoImg = styled('img')`
  width: 101px;
  height: 64px;
  object-fit: contain;
`;
