import { Col, Input } from 'antd';

import { SiteContent } from '../../services/requests';
import FormItem from './FormItem';

export default function ContactForm(props: {
  getValue: (valName: keyof SiteContent) => any;
  setValue: (valName: keyof SiteContent, newValue: any) => void;
}) {
  return (
    <Col>
      <FormItem field="phone">
        <Input
          size="large"
          value={props.getValue('phone')}
          onChange={(e) => props.setValue('phone', e.target.value)}
        />
      </FormItem>
      <FormItem field="zalo">
        <Input
          size="large"
          value={props.getValue('zalo')}
          onChange={(e) => props.setValue('zalo', e.target.value)}
        />
      </FormItem>
      <FormItem field="facebook">
        <Input
          size="large"
          value={props.getValue('facebook')}
          onChange={(e) => props.setValue('facebook', e.target.value)}
        />
      </FormItem>
      <FormItem field="instagram">
        <Input
          size="large"
          value={props.getValue('instagram')}
          onChange={(e) => props.setValue('instagram', e.target.value)}
        />
      </FormItem>
    </Col>
  );
}
