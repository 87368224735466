import { Flex, Progress, Spin, Typography } from 'antd';
import { differenceInMinutes, differenceInSeconds } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MiddleBlock from '../../common/MiddleBlock';
import {
  RunStatus,
  SiteContent,
  useCheckPublishSiteStatus,
  usePublishSite,
  useUpdateSiteOwner,
} from '../../services/requests';
import EmailInput from '../EmailInput';
import ErrorPage from '../ErrorPage';
import { SiteQr } from '../SiteQr';

export enum LocalstorageKey {
  runId = 'runId',
  runCreatedAt = 'runCreatedAt',
}

function getProgressPercent(runCreatedAt: Date) {
  const currentTime = new Date();
  const secondsPass = differenceInSeconds(currentTime, runCreatedAt);
  const expectedDurationSeconds = EXPECTED_DURATION_MINUTES * 60;
  return Math.min(
    Math.floor((secondsPass / expectedDurationSeconds) * 100),
    99,
  );
}

function isRunCreatedAtExpired(runCreatedAt: string) {
  let runCreatedAtDate = null;
  try {
    runCreatedAtDate = new Date(runCreatedAt);
  } catch {
    return false;
  }
  const minutesPassed = differenceInMinutes(new Date(), runCreatedAtDate);
  return minutesPassed > 10;
}

const EXPECTED_DURATION_MINUTES = 4;

function PublishProgress(props: {
  jumpToEnd: boolean;
  runCreatedAt: Date;
  onDone: () => void;
}) {
  const [percent, setPercent] = useState(
    getProgressPercent(props.runCreatedAt),
  );
  const { onDone } = props;

  useEffect(() => {
    if (percent === 100) {
      onDone();
    }
  }, [percent, onDone]);

  useEffect(() => {
    const maxPercent = props.jumpToEnd ? 100 : 99;
    const intervalTime = props.jumpToEnd
      ? 50
      : (EXPECTED_DURATION_MINUTES * 60 * 1000) / 100;
    const interval = setInterval(() => {
      setPercent((o) => Math.min(o + 1, maxPercent));
    }, intervalTime);
    return () => clearInterval(interval);
  }, [props.jumpToEnd]);

  return (
    <Progress
      percent={percent}
      percentPosition={{ align: 'end', type: 'inner' }}
      size={[300, 20]}
      strokeColor="#001342"
    />
  );
}
const RUN_FINISH_STATUS = [RunStatus.FAILURE, RunStatus.SUCCESS];

export function Publish(props: {
  siteId: string;
  ownerEmail?: string;
  siteContent: SiteContent;
}) {
  const { t } = useTranslation();

  const storedRunId = localStorage.getItem(LocalstorageKey.runId);
  const storedRunCreatedAt = localStorage.getItem(LocalstorageKey.runCreatedAt);

  const [initialize, setInitialize] = useState(false);

  const publishSiteMutation = usePublishSite(props.siteId);
  const siteOwnerMutation = useUpdateSiteOwner(props.siteId);
  const handleUpdateOwnerEmail = (ownerEmail: string) => {
    siteOwnerMutation.mutateAsync(ownerEmail);
  };
  const startNewRun = useCallback(async () => {
    const runId = await publishSiteMutation.mutateAsync();
    localStorage.setItem(LocalstorageKey.runId, runId);
    localStorage.setItem(
      LocalstorageKey.runCreatedAt,
      new Date().toISOString(),
    );
    return runId;
  }, [publishSiteMutation]);

  const initPublish = useCallback(async () => {
    let clearCalled = false;
    const clear = () => {
      clearCalled = true;
    };
    if (!storedRunId && storedRunCreatedAt) clear();
    if (storedRunId && !storedRunCreatedAt) clear();
    if (storedRunCreatedAt && isRunCreatedAtExpired(storedRunCreatedAt))
      clear();

    if (!storedRunId || clearCalled) {
      await startNewRun();
    }
    setInitialize(true);
  }, [storedRunId, storedRunCreatedAt, startNewRun]);

  useEffect(() => {
    initPublish();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (publishSiteMutation.isError) {
    return <ErrorPage />;
  }

  return (
    <MiddleBlock id="publish" title="" content={t('publish.title')}>
      <Flex vertical justify="center" gap="large">
        {storedRunId && storedRunCreatedAt && initialize ? (
          <PublishStatus
            siteId={props.siteId}
            runCreatedAt={new Date(storedRunCreatedAt)}
            runId={storedRunId}
            subdomain={props.siteContent.subdomain}
          />
        ) : (
          <Spin size="large" />
        )}
        {!props.ownerEmail && (
          <>
            <Typography.Text>{t('publish.signupBody')}</Typography.Text>
            <EmailInput onSubmit={handleUpdateOwnerEmail} />
          </>
        )}
      </Flex>
    </MiddleBlock>
  );
}

export function PublishStatus(props: {
  siteId: string;
  runId: string;
  runCreatedAt: Date;
  subdomain?: string;
}) {
  const { t } = useTranslation();

  const [runStatus, setRunStatus] = useState<RunStatus>();
  const [showSuccess, setShowSuccess] = useState(false);

  const checkStatusQuery = useCheckPublishSiteStatus(
    props.siteId,
    runStatus && RUN_FINISH_STATUS.includes(runStatus) ? null : props.runId,
  );

  const handleProgressDone = () => {
    if (runStatus === RunStatus.SUCCESS) setShowSuccess(true);
  };

  useEffect(() => {
    if (checkStatusQuery.data) {
      setRunStatus(checkStatusQuery.data);
    }
  }, [checkStatusQuery.data]);

  if (showSuccess) {
    return (
      <Flex vertical gap="large">
        <Typography.Title>{t('publish.success.title')}</Typography.Title>
        {props.subdomain && <SiteQr subdomain={props.subdomain} />}
        <Typography.Text style={{ whiteSpace: 'pre-wrap' }}>
          {t('publish.success.body')}
        </Typography.Text>
      </Flex>
    );
  }

  if (runStatus === RunStatus.FAILURE) {
    return <ErrorPage />;
  }

  return (
    <Flex vertical gap="large">
      <PublishProgress
        runCreatedAt={new Date(props.runCreatedAt)}
        jumpToEnd={runStatus === RunStatus.SUCCESS}
        onDone={handleProgressDone}
      />
      <Typography.Text style={{ whiteSpace: 'pre-wrap' }}>
        {t('publish.loading.body')}
      </Typography.Text>
    </Flex>
  );
}
