import { Flex, Typography } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export default function FormItem(props: {
  field: string;
  children: ReactNode;
}) {
  const { t } = useTranslation();
  return (
    <Flex style={{ paddingTop: 20, paddingBottom: 10 }} vertical gap="small">
      <Typography.Title level={4}>
        {t(`form.${props.field}.label`)}
      </Typography.Title>
      <Typography.Text>{t(`form.${props.field}.helperText`)}</Typography.Text>
      {props.children}
    </Flex>
  );
}
