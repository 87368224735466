import { Col, Row } from 'antd';
import { Slide } from 'react-awesome-reveal';
import { withTranslation } from 'react-i18next';

import { Button } from '../../common/Button';
import Block from './Block';
import Input from './Input';
import { ButtonContainer, ContactContainer, FormGroup, Span } from './styles';
import TextArea from './TextArea';
import { ContactProps, ValidationTypeProps } from './types';
import { useForm } from './useForm';
import validate from './validationRules';

const ContactForm = ({ title, content, id, t }: ContactProps) => {
  const { values, errors, handleChange, handleSubmit } = useForm(validate);

  const ValidationType = ({ type }: ValidationTypeProps) => {
    const ErrorMessage = errors[type as keyof typeof errors];
    return <Span>{ErrorMessage}</Span>;
  };

  return (
    <ContactContainer id={id}>
      <Row justify="space-between" align="middle">
        <Col lg={12} md={11} sm={24} xs={24}>
          <Slide direction="left" triggerOnce>
            <Block title={title} content={content} />
          </Slide>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Slide direction="right" triggerOnce>
            <FormGroup autoComplete="off" onSubmit={handleSubmit}>
              <Col span={24}>
                <Input
                  label={t('contactUs.name')}
                  type="text"
                  name="name"
                  placeholder={t('contactUs.name')}
                  value={values.name || ''}
                  onChange={handleChange}
                />
                <ValidationType type="name" />
              </Col>
              <Col span={24}>
                <Input
                  label={t('contactUs.email')}
                  type="text"
                  name="email"
                  placeholder={t('contactUs.email')}
                  value={values.email || ''}
                  onChange={handleChange}
                />
                <ValidationType type="email" />
              </Col>
              <Col span={24}>
                <TextArea
                  label={t('contactUs.message')}
                  placeholder={t('contactUs.message')}
                  value={values.message || ''}
                  name="message"
                  onChange={handleChange}
                />
                <ValidationType type="message" />
              </Col>
              <ButtonContainer>
                <Button name="submit">{t('contactUs.submit')}</Button>
              </ButtonContainer>
            </FormGroup>
          </Slide>
        </Col>
      </Row>
    </ContactContainer>
  );
};

export default withTranslation()(ContactForm);
