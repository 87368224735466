import { TFunction, withTranslation } from 'react-i18next';

import ContentBlock from '../common/ContentBlock';
import { List } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { RouteName } from '../router/config';

function ServicesSection(props: { t: TFunction }) {
  const { t } = props;
  const navigate = useNavigate();

  const data = [
    {
      title: t('servicesSection.services.domain'),
    },
    {
      title: t('servicesSection.services.components'),
    },
    {
      title: t('servicesSection.services.ads'),
    },
    {
      title: t('servicesSection.services.translate'),
    },
  ];

  return (
    <ContentBlock
      title={t('servicesSection.title')}
      content={t('servicesSection.body')}
      id="services"
      direction="right"
      button={[
        {
          title: t('servicesSection.button'),
          onClick: () => {
            navigate(RouteName.contact);
          },
        },
      ]}
      icon="graphs.svg"
      iconStyle={{ height: '100%', width: '100%' }}
    >
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta title={item.title} avatar={<CheckCircleFilled />} />
          </List.Item>
        )}
      />
    </ContentBlock>
  );
}

export default withTranslation()(ServicesSection);
