import { Button, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useGetSite } from '../../services/requests';
import LoaderPage from '../LoaderPage';
import SiteInfo from '../SiteInfo';

function Review(props: {
  siteId: string;
  onEdit: () => void;
  onSave: () => void;
  onPublish: () => void;
}) {
  const getSiteQuery = useGetSite(props.siteId);
  const { t } = useTranslation();

  const { data: siteContent } = getSiteQuery;
  if (getSiteQuery.isLoading || getSiteQuery.isFetching || !siteContent) {
    return <LoaderPage />;
  }

  return (
    <>
      <SiteInfo siteContent={siteContent} />
      <Flex align="center" vertical gap="small">
        <Button type="primary" onClick={props.onPublish} size="large">
          {t('review.publishButton')}
        </Button>
        <Typography style={{ textAlign: 'center' }}>
          {t('review.or')}
        </Typography>
        <Button onClick={props.onSave}>{t('review.saveButton')}</Button>
        <Button onClick={props.onEdit}>{t('review.editButton')}</Button>
      </Flex>
    </>
  );
}

export default Review;
