import { lazy } from 'react';
import { useTranslation } from 'react-i18next';

import ContactForm from '../components/ContactForm';

const Container = lazy(() => import('../common/Container'));

const Contact = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <ContactForm
        id="contact"
        title={t('contactUs.title')}
        content={t('contactUs.body')}
      />
    </Container>
  );
};

export default Contact;
