import { useEffect, useState } from 'react';

import { useCreateSite, useGetSite } from '../services/requests';

export enum LocalstorageKey {
  siteId = 'siteId',
}

export function useGetOrCreateSite(options: { allowCreate: boolean }) {
  const storedSiteId = localStorage.getItem(LocalstorageKey.siteId);
  const getSiteQuery = useGetSite(storedSiteId || undefined);
  const createSiteMutation = useCreateSite();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!storedSiteId) {
      if (!options.allowCreate) {
        setIsError(true);
        return;
      }
      createSiteMutation.mutateAsync().then((newSiteId) => {
        localStorage.setItem(LocalstorageKey.siteId, newSiteId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    siteId: storedSiteId,
    siteContent: getSiteQuery.data,
    isError: isError || getSiteQuery.isError || createSiteMutation.isError,
  };
}
