import { Col } from 'antd';

import { SiteContent } from '../../services/requests';
import FilesUpload from './FilesUpload';
import FormItem from './FormItem';

export default function ImagesForm(props: {
  getValue: (valName: keyof SiteContent) => any;
  setValue: (valName: keyof SiteContent, newValue: any) => void;
  uploadUrl: string;
}) {
  const handleSingleImageUpdate =
    (valName: keyof SiteContent) => (newImageUrls: string[]) => {
      props.setValue(
        valName,
        newImageUrls.length > 0 ? newImageUrls[0] : undefined,
      );
    };
  const handleMultipleImageUpdate =
    (valName: keyof SiteContent) => (newImageUrls: string[]) => {
      props.setValue(valName, newImageUrls);
    };
  return (
    <Col>
      <FormItem field="logo">
        <FilesUpload
          count={1}
          uploadUrl={props.uploadUrl}
          onUpdate={handleSingleImageUpdate('logo_url')}
          initialUrls={
            props.getValue('logo_url') ? [props.getValue('logo_url')] : []
          }
        />
      </FormItem>
      <FormItem field="mainImage">
        <FilesUpload
          count={1}
          uploadUrl={props.uploadUrl}
          onUpdate={handleSingleImageUpdate('main_image_url')}
          initialUrls={
            props.getValue('main_image_url')
              ? [props.getValue('main_image_url')]
              : []
          }
        />
      </FormItem>
      <FormItem field="images">
        <FilesUpload
          count={5}
          uploadUrl={props.uploadUrl}
          onUpdate={handleMultipleImageUpdate('image_urls')}
          initialUrls={props.getValue('image_urls')}
        />
      </FormItem>
      <FormItem field="menu">
        <FilesUpload
          count={5}
          uploadUrl={props.uploadUrl}
          onUpdate={handleMultipleImageUpdate('menu_urls')}
          initialUrls={props.getValue('menu_urls')}
        />
      </FormItem>
    </Col>
  );
}
