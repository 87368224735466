import { EditOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { RouteName } from '../router/config';
import ErrorPage from './ErrorPage';
import LoaderPage from './LoaderPage';
import SiteInfo from './SiteInfo';
import { useGetOrCreateSite } from './useGetOrCreateSite';
import { StepName } from './SiteCreationForm';
import { SiteQr } from './SiteQr';

export default function SiteDashboard() {
  const getCurrentSite = useGetOrCreateSite({ allowCreate: false });
  const navigate = useNavigate();
  const handleNewSiteClick = () => {
    navigate(RouteName.siteForm + `?step=${StepName.BASIC}`);
  };
  const { t } = useTranslation();

  if (getCurrentSite.isError) {
    return <ErrorPage />;
  }

  if (!getCurrentSite.siteId || !getCurrentSite.siteContent) {
    return <LoaderPage />;
  }

  return (
    <Flex
      vertical
      align="center"
      justify="center"
      style={{ width: '100%', paddingTop: '10px', paddingBottom: '10px' }}
      gap="large"
    >
      <h6>{getCurrentSite.siteContent.name}</h6>
      <div style={{ width: '100%' }}>
        <SiteInfo siteContent={getCurrentSite.siteContent} />
      </div>
      <Button
        type="primary"
        onClick={handleNewSiteClick}
        icon={<EditOutlined />}
      >
        {t('edit')}
      </Button>
      {getCurrentSite.siteContent.subdomain && (
        <SiteQr subdomain={getCurrentSite.siteContent.subdomain} />
      )}
    </Flex>
  );
}
