import { Col, Row } from 'antd';
import { TFunction, withTranslation } from 'react-i18next';

import Container from '../../common/Container';
import LanguageButton from '../../common/Language/LanguageButton';
import { RouteName } from '../../router/config';
import {
  Chat,
  FooterSection,
  Label,
  Language,
  LanguageSwitchContainer,
  Para,
} from './styles';

interface FooterProps {
  t: TFunction;
}

const Footer = ({ t }: FooterProps) => {
  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t('contact')}</Language>
              <Para>{t('footer.contactUsBody')}</Para>
              <a href={RouteName.contact}>
                <Chat>{t('footer.contactUsButton')}</Chat>
              </a>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t('language')}</Label>
              <LanguageSwitchContainer>
                <LanguageButton lng="en" />
                <LanguageButton lng="ko" />
                <LanguageButton lng="vi" />
                <LanguageButton lng="ru" />
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
