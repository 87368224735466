import Container from '../common/Container';
import IntroSection from '../sections/IntroSection';
import LoginSection from '../sections/LoginSection';
import PricingSection from '../sections/PricingSection';

export default function Component(): JSX.Element {
  return (
    <Container>
      <IntroSection />
      <LoginSection />
      <PricingSection />
    </Container>
  );
}
