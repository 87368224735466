import { lazy } from 'react';

import { SiteCreationWrapper } from '../components/SiteCreationForm';

const Container = lazy(() => import('../common/Container'));

const SiteForm = () => {
  return (
    <Container>
      <SiteCreationWrapper />
    </Container>
  );
};

export default SiteForm;
