import { TFunction, withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import MiddleBlock from '../common/MiddleBlock';
import { RouteName } from '../router/config';

function ErrorPage(props: { t: TFunction }) {
  const { t } = props;
  const navigate = useNavigate();
  const handleContactUsClick = () => {
    navigate(RouteName.contact);
  };
  return (
    <MiddleBlock
      id="error"
      title={t('error.title')}
      content={t('error.body')}
      button={t('error.contactUs')}
      onClick={handleContactUsClick}
    />
  );
}

export default withTranslation()(ErrorPage);
