import { Col, Row, Spin } from 'antd';

import {
  ContentWrapper,
  MiddleBlockSection,
} from '../common/MiddleBlock/styles';

export default function LoaderPage() {
  return (
    <MiddleBlockSection style={{ minHeight: '60vh' }}>
      <Row justify="center" align="middle" id={'loader'}>
        <ContentWrapper>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Spin size="large" />
          </Col>
        </ContentWrapper>
      </Row>
    </MiddleBlockSection>
  );
}
