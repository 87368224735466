import styled from 'styled-components';

import { SvgIcon } from '../../common/SvgIcon';
import i18n from '../../translation';
export const LanguageSwitch = styled('div')`
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;
const LanguageButton = ({ lng, size }: { lng: string; size?: number }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };
  const iconSize = `${size || 30}px`;
  return (
    <LanguageSwitch onClick={() => handleChange(lng)}>
      <SvgIcon
        src={`${lng}.svg`}
        aria-label="homepage"
        width={iconSize}
        height={iconSize}
      />
    </LanguageSwitch>
  );
};

export default LanguageButton;
