import { Flex, QRCode, Typography } from 'antd';

export function getSiteUrl(subdomain: string) {
  return `https://${subdomain}.nha-hang.com`;
}

export function SiteQr(props: { subdomain: string }) {
  return (
    <Flex vertical justify="center" align="center">
      <QRCode errorLevel="H" value={getSiteUrl(props.subdomain)} />
      <Typography.Link target="_blank" href={getSiteUrl(props.subdomain)}>
        {getSiteUrl(props.subdomain)}
      </Typography.Link>
    </Flex>
  );
}
