import { InputProps } from '../TextArea';
import { Label } from '../TextArea/styles';
import { Container, StyledInput } from './styles';

const Input = ({ label, name, placeholder, onChange }: InputProps) => (
  <Container>
    <Label htmlFor={name}>{label}</Label>
    <StyledInput
      placeholder={placeholder}
      name={name}
      id={name}
      onChange={onChange}
    />
  </Container>
);

export default Input;
