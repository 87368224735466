import axios, { AxiosError } from 'axios';

import { SERVER_URL } from './common';
import logger from './logger';

const TIMEOUT = 11000;

export const serverApi = axios.create({
  baseURL: SERVER_URL,
  timeout: TIMEOUT,
  headers: {
    'ngrok-skip-browser-warning': true,
    'Content-Type': 'application/json',
  },
});

serverApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: AxiosError) {
    logger.error(
      'API call failed',
      {
        path: error.request.path,
        message: error.message,
        statusCode: error.status,
      },
      error,
    );
    return Promise.reject(error);
  },
);
