import { withTranslation } from 'react-i18next';

import { RouteName } from '../../router/config';
import { LogoContainer, LogoImg } from './styles';

const Logo = () => {
  return (
    <LogoContainer to={RouteName.home} aria-label="homepage">
      <LogoImg src="/img/svg/logo.svg" alt={'logo'} color="#2e186a" />
    </LogoContainer>
  );
};

export default withTranslation()(Logo);
