import { Col, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { SiteContent } from '../../services/requests';
import FormItem from './FormItem';

export default function BasicForm(props: {
  getValue: (valName: keyof SiteContent) => any;
  setValue: (valName: keyof SiteContent, newValue: any) => void;
}) {
  return (
    <Col>
      <FormItem field="name">
        <Input
          size="large"
          value={props.getValue('name')}
          onChange={(e) => props.setValue('name', e.target.value)}
        />
      </FormItem>
      <FormItem field="address">
        <TextArea
          size="middle"
          value={props.getValue('address_text')}
          onChange={(e) => props.setValue('address_text', e.target.value)}
        />
      </FormItem>
      <FormItem field="hours">
        <TextArea
          size="middle"
          value={props.getValue('hours')}
          onChange={(e) => props.setValue('hours', e.target.value)}
        />
      </FormItem>
    </Col>
  );
}
