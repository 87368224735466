import { Col, Drawer, Row } from 'antd';
import { useEffect, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import Container from '../../common/Container';
import LanguageDropdown from '../../common/Language/LanguageDropdown';
import { scrollToSection } from '../../common/utils/utils';
import { RouteName } from '../../router/config';
import Logo from '../Logo';
import {
  Burger,
  CustomNavLinkSmall,
  HeaderSection,
  Hidden,
  Label,
  NotHidden,
  Outline,
  Span,
} from './styles';
import { useGetOrCreateSite } from '../useGetOrCreateSite';

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [scrollToId, setScrollToId] = useState<string>();
  const getCurrentSite = useGetOrCreateSite({ allowCreate: false });

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const handleScroll = (id: string) => {
    scrollToSection(id);
    setScrollToId(undefined);
    setVisibility(false);
  };

  useEffect(() => {
    if (scrollToId) {
      if (location.pathname === RouteName.home) {
        handleScroll(scrollToId);
      } else {
        navigate(RouteName.home);
      }
    }
  }, [location.pathname, scrollToId, navigate]);
  const scrollTo = (sectionId: string) => {
    setScrollToId(sectionId);
  };

  const handleContactUsClick = () => {
    toggleButton();
    navigate(RouteName.contact);
  };
  const handleServicesClick = () => {
    toggleButton();
    navigate(RouteName.services);
  };

  return (
    <HeaderSection
      style={{
        backgroundColor: '#ffffffbb',
      }}
    >
      <Container>
        <Row justify="space-between">
          <Row>
            <Logo />
            <Hidden>
              <LanguageDropdown />
            </Hidden>
          </Row>
          <NotHidden>
            {!getCurrentSite.siteContent?.name && (
              <CustomNavLinkSmall onClick={() => scrollTo('login')}>
                <Span>{t('login')}</Span>
              </CustomNavLinkSmall>
            )}
            <CustomNavLinkSmall onClick={() => scrollTo('price')}>
              <Span>{t('pricing')}</Span>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall onClick={() => navigate(RouteName.services)}>
              <Span>{t('services')}</Span>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall onClick={() => navigate(RouteName.contact)}>
              <Span>{t('contact')}</Span>
            </CustomNavLinkSmall>
            <LanguageDropdown />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: '2.5rem' }}>
            <Label onClick={toggleButton}>
              <Col span={12}></Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          {!getCurrentSite.siteContent?.name && (
            <CustomNavLinkSmall onClick={() => scrollTo('login')}>
              <Span>{t('login')}</Span>
            </CustomNavLinkSmall>
          )}
          <CustomNavLinkSmall onClick={() => scrollTo('price')}>
            <Span>{t('pricing')}</Span>
          </CustomNavLinkSmall>
          <CustomNavLinkSmall onClick={handleServicesClick}>
            <Span>{t('services')}</Span>
          </CustomNavLinkSmall>
          <CustomNavLinkSmall onClick={handleContactUsClick}>
            <Span>{t('contact')}</Span>
          </CustomNavLinkSmall>
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
