import { CheckOutlined } from '@ant-design/icons';
import { Button, Input, Space, Spin, Typography } from 'antd';
import { ChangeEvent, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';

function EmailInput(props: {
  onSubmit: (email: string) => void;
  onChange?: (email: string) => void;
  isLoading?: boolean;
  errorText?: string;
  t: TFunction;
}) {
  const [email, setEmail] = useState<string>('');
  const { t } = props;

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
    props.onChange?.(event.target.value);
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Space.Compact style={{ width: '350px' }}>
        <Input
          value={email}
          onChange={handleChange}
          addonBefore={t('email') + ':'}
          type="email"
          disabled={props.isLoading}
        />
        <Button
          type="primary"
          onClick={() => props.onSubmit(email)}
          disabled={props.isLoading}
        >
          {props.isLoading ? <Spin size="small" /> : <CheckOutlined />}
        </Button>
      </Space.Compact>
      {props.errorText && (
        <Typography.Text
          style={{ paddingTop: 10, paddingLeft: 20 }}
          type="danger"
        >
          {props.errorText}
        </Typography.Text>
      )}
    </div>
  );
}
export default withTranslation()(EmailInput);
